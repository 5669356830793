import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import form from './images/form.png';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default function Fill() {
    return (
        <div>
            <Typography variant="body2">
                入会申込書の提出はお済みですか？まだ提出していない方は、会則をご確認の上、入会申込書の提出をお願いします。
            </Typography>
            <Box my={2} />
            <Button
                variant="contained"
                color="primary"
                startIcon={<OpenInNewIcon />}
                target="_blank"
                href="https://drive.google.com/open?id=1GNbeTMODX03cKg6YARG0atce9Dt73HTo"
                rel="noopener"
            >
                会則を確認する
            </Button>
            <Box my={2} />
            <Button
                variant="contained"
                color="primary"
                startIcon={<OpenInNewIcon />}
                target="_blank"
                href="https://forms.gle/WQdHVGWp5PJFBa46A"
                rel="noopener"
            >
                入会申込書を提出する
            </Button>
            <img src={form} alt="form" />
        </div>
    );
}