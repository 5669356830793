import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';

function createFeeData(type, fee) {
    return { type, fee };
}

function createAccountInfoData(name, value) {
    return { name, value };
}

const feeRows = [
    createFeeData('新入会員', '3,000 円'),
    createFeeData('既存会員', '2,000 円'),
];

const accountInfoRows = [
    createAccountInfoData('金融機関名', 'みずほ銀行'),
    createAccountInfoData('金融機関コード', '0001'),
    createAccountInfoData('支店名', '津田沼支店'),
    createAccountInfoData('支店コード', '334'),
    createAccountInfoData('預金種目', '普通'),
    createAccountInfoData('口座番号', '1993031'),
    createAccountInfoData('受取人名', 'ワセタ゛タ゛イカ゛クコンヒ゜ユ－タケンキユウカイＷＩＮＣ'),
];

export default function SimpleTable() {

    return (
        <div>
            <Typography variant="body2">
                まず次の表を見て、ご自身のお振込金額をご確認ください。
            </Typography>
            <Box my={2} />
            <Alert severity="info">振込手数料は振込人負担となります。</Alert>
            <Box my={2} />
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>種別</TableCell>
                            <TableCell>お振込金額</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feeRows.map((row) => (
                            <TableRow key={row.type}>
                                <TableCell component="th" scope="row">
                                    {row.type}
                                </TableCell>
                                <TableCell>{row.fee}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box my={2} />
            <Typography variant="body2">
                コンビニATM、銀行ATM、銀行窓口、インターネットバンキング等を利用して次の口座にお振込ください。詳しい振込方法につきましては、ご利用の金融機関にお尋ねください。
            </Typography>
            <Box my={2} />
            <Alert severity="warning">振込人名義は会員の名義と一致する必要があります。振込を保証人に依頼する場合はご注意ください。</Alert>
            <Box my={2} />
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        {accountInfoRows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box my={2} />
        </div>
    );
}
