import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MyAppBar from './MyAppBar';
import MyStepper from './MyStepper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import pink from '@material-ui/core/colors/pink';

const theme = createMuiTheme({
  palette: {
    primary: pink,
    secondary: pink,
  },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MyAppBar />
            <Container maxWidth="md">
                <MyStepper />
            </Container>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
