import React from 'react';
import Fee from './Fee'
import payment from './images/payment.png';

export default function Pay() {
    return (
        <div>
            <Fee />
            <img src={payment} alt="payment" />
        </div>
    );
}