import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function MyAppBar() {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          コンピュータ研究会・WINC
        </Typography>
        <IconButton aria-label="twitter" color="inherit" target="_blank" href="https://www.winc.ne.jp/" rel="noopener">
          <HomeIcon />
        </IconButton>
        <IconButton aria-label="homepage" edge="end" color="inherit" target="_blank" href="https://twitter.com/winc_w" rel="noopener">
          <TwitterIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}