import React from 'react';
import receipt from './images/receipt.png';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

export default function Receive() {
    return (
        <div>
            <Typography variant="body2">
                振込が完了したら、必ず振込金受取書を受け取ってください。
            </Typography>
            <Box my={2} />
            <Alert severity="info">振込金受取書は大切に保管してください。</Alert>
            <img src={receipt} alt="receipt" />
        </div>
    );
}